import React from "react"
import { graphql, Link, navigate } from "gatsby"
import find from "lodash.find"
import shuffle from "lodash.shuffle"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ReactPlayer from "react-player"
import BlockContent from "@sanity/block-content-to-react"

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import BeFeaturedBanner from "../../components/beFeaturedBanner"
import { format } from "date-fns"
import { motion } from "framer-motion"

import futureDrivenThumbnail from "../../images/featured/future_driven_thumbnail.png"

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

function ResourcesPage({ data, location }) {
  const urlID = new URLSearchParams(location.search).get("id")
  if (!urlID) {
    if (typeof window !== `undefined`) {
      navigate("/resources")
    }
    return <></>
  }

  const {
    node: { date, feed, name, description, guests, _id, event },
  } = find(data.tipTalks.edges, o => o?.node._id === urlID)
  const related = data.tipTalks.edges.filter(
    tiptalk => tiptalk.node._id !== _id
  )

  description?.map(i => {
    i.markDefs = []
    return i
  })

  return (
    <Layout title="resources">
      <Seo title={name} />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <section className="pb-20 pt-32 w-full bg-dark-200">
          <div className="flex flex-col mx-auto w-10/12 max-w-3xl text-white">
            <ReactPlayer width="100%" url={feed} />
            <p className="mt-5 text-gray-300 text-sm italic font-bold">
              {format(new Date(date ?? null), "MMMM d, yyyy")}
            </p>
            <h2 className="my-2 text-xl font-bold">{name}</h2>
            <div className="text-gray-300 text-base leading-6">
              <BlockContent blocks={description} />
            </div>
            {guests?.length > 0 && (
              <div>
                <h4 className="my-8 text-gold text-sm font-bold">FEATURING</h4>
                <div className="flex flex-wrap">
                  {guests.map((guest, i) => (
                    <div className="flex items-center mb-4 mr-8" key={i}>
                      <img
                        src={
                          guest.image?.asset?.url ??
                          `https://icotar.com/initials/${encodeURIComponent(
                            guest.name
                          )}`
                        }
                        alt={guest.name}
                        className="mr-4 w-12 rounded-full"
                      />
                      <span className="text-sm font-bold">{guest.name}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </section>

        {related?.length > 0 && (
          <section className="py-20 w-full bg-black">
            <div className="flex flex-col mx-auto w-10/12 max-w-3xl text-white">
              <h4 className="mb-6 text-gold text-sm font-bold">
                RELATED VIDEOS
              </h4>
              {shuffle(related)
                .map(tiptalk => (
                  <Link
                    to={`/resources/tiptalks?id=${tiptalk.node._id}`}
                    key={tiptalk.node._id}
                  >
                    <div className="related-tiptalk-min-h flex flex-col items-center justify-center mb-4 md:flex-row md:items-stretch">
                      <img
                        src={
                          tiptalk.node.thumbnail?.asset?.url ??
                          futureDrivenThumbnail
                        }
                        alt="thumbnail"
                        className="w-72"
                      />
                      <div className="inset-0 flex flex-col justify-center p-6 w-72 bg-dark-200 md:w-full">
                        <p className="text-lg font-bold">{tiptalk.node.name}</p>
                        <div className="line-clamp-2 text-gray-400">
                          <BlockContent
                            blocks={tiptalk.node.description?.map(i => {
                              i.markDefs = []
                              return i
                            })}
                          />
                        </div>

                        <p className="flex items-center mt-2">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.5547 5.16795C7.24784 4.96338 6.8533 4.94431 6.52814 5.11833C6.20298 5.29235 6 5.63121 6 6V10C6 10.3688 6.20298 10.7077 6.52814 10.8817C6.8533 11.0557 7.24784 11.0366 7.5547 10.8321L10.5547 8.8321C10.8329 8.6466 11 8.3344 11 8C11 7.66565 10.8329 7.35342 10.5547 7.16795L7.5547 5.16795Z"
                              fill="white"
                            />
                          </svg>
                          <span className="ml-2">
                            {format(
                              new Date(tiptalk.node.date ?? null),
                              "MMMM d, yyyy"
                            )}
                          </span>
                        </p>
                      </div>
                    </div>
                  </Link>
                ))
                .slice(0, 2)}
            </div>
          </section>
        )}

        {event?.sponsors.length > 0 && (
          <section className="my-20">
            <h2 className="mb-10 text-center text-dark-100 text-2xl font-bold">
              Event Sponsors
            </h2>
            <div className="fade-edges relative mx-auto w-full overflow-x-hidden lg:w-10/12">
              <Swiper
                slidesPerView={2}
                breakpoints={{
                  768: {
                    slidesPerView: 3,
                  },
                  1280: {
                    slidesPerView: 4,
                  },
                  1920: {
                    slidesPerView: 5,
                  },
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                loop={true}
              >
                {event.sponsors.map((sponsor, i) => (
                  <SwiperSlide key={i}>
                    <a
                      href={sponsor.company.url}
                      target="_blank"
                      rel="noreferrer"
                      className="grayscale-effect relative block transition-all"
                    >
                      <img
                        src={sponsor.company.logo.asset.url}
                        alt={sponsor.company.name}
                        className="mx-auto w-32 h-16 object-contain object-center"
                      />
                    </a>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </section>
        )}

        <BeFeaturedBanner />
      </motion.div>
    </Layout>
  )
}

export default ResourcesPage

export const query = graphql`
  {
    tipTalks: allSanityTiptalk {
      edges {
        node {
          _id
          date
          feed
          name
          description {
            children {
              marks
              text
              _key
              _type
            }
            list
            style
            _key
            _rawChildren
            _type
          }
          guests {
            name
            image {
              asset {
                url
              }
            }
          }
          thumbnail {
            asset {
              url
            }
          }
          event {
            sponsors {
              company {
                logo {
                  asset {
                    url
                  }
                }
                url
                name
              }
            }
          }
        }
      }
    }
  }
`
